export default class {
  constructor() {
    // Nothing to do
  }

  executePromise(promise, properties, component, clearDirty) {
    GLib.http.startIndicator(component);

    return promise.then((result) => {
      // Right now it makes more sense to always clear dirty even when it is a destroy
      // action because any data that is being entered by the user gets nuked when the
      // page gets refreshed.
      // To fix this, I think it's better done at glib level or don't use `windows/reload`
      //
      // if (clearDirty) {
      //   Utils.http.clearDirtyState();
      // }

      Utils.http.clearDirtyState();

      GLib.action.execute(properties.onSuccess, component);

      GLib.http.stopIndicator(component);
    }).catch(err => {
      // This can happen if reject() is called without an argument.
      const e = err ? err.toString() : "Unknown error"

      GLib.action.execute(properties.onFailure || {
        "action": "snackbars/alert",
        "message": e,
        "styleClasses": [
          "error"
        ]
      }, component);

      GLib.http.stopIndicator(component);

      console.error("Action error:", err);
      $tpu.u.errors.report(err)
    });
  }

  /// Page refresh

  _refreshPage() {
    // In the future, we should be passing `false` and rely on updatedAt, but we'll use `true` here
    // because updating checklists does change card.updatedAt yet.
    if (this.refreshCard(true)) {
      // Allow time for card refresh to finish.
      setTimeout(() => {
        this.refreshProjectShow();
      }, 100)
    } else {
      this.refreshProjectShow();
    }
  }

  refreshCard(force) {
    const cardPage = GLib.component.findById("card_show");
    if (cardPage) {
      cardPage.action_updateContent(force);
      return true
    }
    return false
  }

  invalidateProjectShow() {
    const projectPage = GLib.component.findById("project_show_content");
    projectPage.action_invalidateList();
  }

  refreshProjectShow(ifApplicable) {
    const projectPage = GLib.component.findById("project_show_content");
    if (ifApplicable) {
      if (projectPage) {
        projectPage.action_updateList();
      }
    } else {
      projectPage.action_updateList();
    }
  }

  // The rule of thumb is do not clear state for destroy actions.
  executeCardUpdate(promise, properties, component, clearState) {
    const refreshAfterExecute = promise.then(() => {
      if (clearState) {
        $tpu.editing = {}
      }
      this._refreshPage()
    })

    return this.executePromise(refreshAfterExecute, properties, component, clearState)

    // // Execute onSuccess before _refreshPage() to make sure that when deleting a card, the dialog gets closed
    // // before we attempt to update the dialog's content.
    // return this.executePromise(promise, properties, component, clearState).then(() => {
    //   if (clearState) {
    //     $tpu.editing = {}
    //   }
    //   this._refreshPage()
    // })
  }

  // The rule of thumb is do not clear state for destroy actions.
  executeCardDestroy(promise, properties, component) {
    const spec = Object.assign({
      "onSuccess": {
        "action": "dialogs/close"
      }
    }, properties)
    // Execute onSuccess before _refreshPage() to make sure that when deleting a card, the dialog gets closed
    // before we attempt to update the dialog's content.
    return this.executePromise(promise, spec, component, false).then(() => {
      // Allow time for dialog to close.
      setTimeout(() => {
        this.refreshProjectShow();
      }, 100)
    })
  }

  executeProjectUpdate(promise, properties, component, clearDirty) {
    const spec = Object.assign({
      "onSuccess": {
        "action": "dialogs/close",
        "onClose": {
          "action": "windows/reload"
        }
      }
    }, properties)

    this.executePromise(promise, spec, component, clearDirty)
  }

  ///
}
